import { fb, validators } from "../../../lib/form";

export function getContactModel(data) {
  let locationList = [
    "Mile End",
    "Blair Athol",
    //"Elizabeth",
    "Hillcrest",
    "Other",
  ].map((x) => {
    return { name: x, value: x };
  });
  locationList = [
    { name: "Please Select Your Location*", value: "" },
    ...locationList,
  ];

  const model = fb.group({
    name: [
      data.name || "",
      [validators.Required()],
      { label: "Name", type: "text" },
    ],
    phone: [
      data.phone || "",
      [validators.Required()],
      { label: "Phone", type: "text" },
    ],
    email: [
      data.email || "",
      [validators.Required(), validators.Email()],
      { label: "Email", type: "email" },
    ],
    location: [
      data.location || "",
      [validators.Required()],
      { label: null, type: "select", options: locationList },
    ],
    enquiry: [
      data.enquiry || "",
      [validators.Required()],
      { label: "Your Message", type: "textarea" },
    ],
    attachments: [
      data.attachments || "",
      [],
      { label: "Attachments", type: "file" },
    ],
  });
  return model;
}
